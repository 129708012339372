.ig-login-ktherobot {
    color:#fff;
    right: 16vw ;
    width:30vw !important; 
    position: absolute;
    top: 50%; 
    transform: translateY(-50%); 
    -ms-transform: translateY(-50%);
    visibility: visible;
}

.ig-login-loginform {
      right: 50%;
      padding:3em !important;
      width:35vw !important;
      max-height: "90vh";
      background-color: #ffffff;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      -ms-transform: translateY(-50%);
}

.ig-login-background{
        width: 100vw !important; 
        height: 100vh; 
        background-color:#000000;
        background-image:url("../../../../public/img/bg-car.jpg"); 
        background-repeat: no-repeat; 
        background-size: 100vw 100vh;
    }

    .ig-login-background-cover{
        width: 100vw !important; 
        height: 100vh; 
        background-color: #0007;
    }

@media (max-width: 480px) { 
   .ig-login-ktherobot {
     visibility: hidden;
   }


   .ig-login-loginform {
        left: 10vw !important;
        padding: 1em !important;
        width:80vw !important;
        background-color: #ffffff;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        -ms-transform: translateY(-50%);
   }

   .ig-login-background{
        width: 100vw !important; 
        height: 100vh; 
        background-color:#000000;
        background-image:url("../../../../public/img/bg-car.jpg"); 
        background-repeat: no-repeat; 
        background-size: cover;
    }


}

@media(max-width: 767px) {

    .ig-login-ktherobot {
        visibility: hidden;
      }
   
   
      .ig-login-loginform {
            left: 10vw !important;
            padding: 2em !important;
            width:80vw !important;
            background-color: #ffffff;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            -ms-transform: translateY(-50%);
      }
   
      .ig-login-background{
           width: 100vw !important; 
           height: 100vh; 
           background-color:#000000;
           background-image:url("../../../../public/img/bg-car.jpg"); 
           background-repeat: no-repeat; 
           background-size: cover;
       }

}

@media(min-width: 768px){



}