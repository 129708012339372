.ig-userManagement-table{
    width: 100%;
}

.ig-userManagement-table > thead tr{
    border-bottom-width: 2px;
    border-bottom-color: #777777;
}

.ig-userManagement-table > thead td {
    padding-left: 0.5em;
    padding-right: 0.5em;
}

.ig-userManagement-table > thead input {
    width: 98%;
}

.ig-userManagement-table > tbody tr{
    border-bottom-width: 2px;
    border-bottom-color: #BBBBBB;
}

.ig-userManagement-table > tbody tr:hover{
    background-color: aqua;
}

.ig-userManagement-table > tbody tr:hover{
    background-color: rgb(228, 225, 253);
}

.ig-userManagement-table-actions-col span {
    margin: 0.2em !important;
    font-size: x-large;
    
    cursor: pointer;
}

.ig-userManagement-table .unlock-user{
    color: #34db0b;
}
.ig-userManagement-table .lock-user{
    color: #939493;
}
.ig-userManagement-table .clear-user{
    color: #05dcec;
}
.ig-userManagement-table .delete-user{
    color: #f00707;
}
.ig-userManagement-table .key-user{
    color: #0019f8;
}
