.ig-login-car {
    background-color: #000000;
    right: 0%;
    top: 0%;
    height: 100vh;
    width:50vw !important; 
    position: fixed;
    visibility: visible;
}

.ig-login-loginform {
      left: 25%;
      padding:3em !important;
      width:30vw !important;
      max-height: "90vh";
      background-color: #ffffff;
      position: absolute;
      top: 50%;
      transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
      text-align: center;
}

.ig-login-loginform img {
    width: 90%;
}

.ig-login-background{
        width: 100vw !important; 
        height: 100vh; 
    }

.ig-login-input{
    border-top-width: 0px !important;
    border-right-width: 0px !important;
    border-left-width: 0px !important;
}

.ig-login-input:focus{
    box-shadow: none !important;
}

.ig-login-btn {
    padding: 10px 30px 10px 30px !important;
    font-weight: bold !important;
}


@media (max-width: 480px) { 
    .ig-login-car {
        background-color: #000000;
        left: 0%;
        top: 0%;
        height: 100vh;
        width:100vw !important; 
        position: absolute;
        visibility:visible;
        z-index: 2 !important;
    }
    
    .ig-login-loginform {
          left: 50% !important;
          top: 50%;
          padding:3em !important;
          width:90vw !important;
          min-width: 90vw !important;
          max-height: "90vh";
          background-color: #ffffff;
          position: absolute;
          transform: translate(-50%,-50%) !important;
          -ms-transform: translate(-50%,-50%) !important;
          z-index: 10 !important;
          text-align: center;
    }

    ig-login-loginform img {
        width: 75%;
    }


}

@media(min-width:481px) and (max-width:767px){

    .ig-login-loginform {
        left: 25%;
        padding:2em !important;
        width:45vw !important;
        max-height: "90vh";
        background-color: #ffffff;
        position: absolute;
        top: 50%;
        transform: translate(-25%,-50%) !important;
        -ms-transform: translate(-25%,-50%) !important;
        text-align: center;
  }

  .ig-login-loginform img {
    width: 75%;
    }

}

@media(min-width:768px) and (max-width:1023px){

    .ig-login-loginform {
        left: 25%;
        padding:3em !important;
        width:45vw !important;
        max-height: "80vh";
        background-color: #ffffff;
        position: absolute;
        top: 50%;
        transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        text-align: center;
  }

  .ig-login-loginform img {
    width: 75%;
    }

}