/* =========== btn Close ==================*/

  .ig-menu-btn-close {
    display: none;
  
  }

.ig-menu-screen-wrapper{
    display: flex;
    flex-wrap: wrap;
}
/* ========== MENU SIDE =======================*/
.ig-menu-hide{
    position: absolute !important;
    left: -50% !important;
}

.ig-menu-side {
    position: sticky;
    width: 300px;
    max-width: 20vw;
    background-color: black;
    height: 96vh;
    margin: 2vh;
    border-radius: 25px;
    padding: 1em;
    z-index: 9999;
    flex-wrap: wrap;
}

.ig-menu-side > .ig-tittle {
    border-bottom-width: 1px !important;
    border-bottom-color: #ffffff !important;
    border-bottom-style: solid;
    min-height: 50px;
    color: #ffffff;
    text-align: center;
    overflow-wrap: break-word;
}

.ig-menu-side ul {
    list-style-type: none;
    color: #ffffff;
    text-align: start;
    padding: 0px;
    font-size: large;
    margin-top: 1em;
    width: 100%;
}

.ig-menu-side li {
    padding-top: 0.5em;
    padding-left: 1em;
    padding-right: 1em;
    padding-bottom: 0.5em;
    margin-top: 0.2em;

    
}

.ig-menu-side li:hover {
    background-color: #888888;
    border-radius: 10px;
    cursor: pointer;

}

.ig-menu-side li.ig-menu-active, .ig-menu-side li:active   {
    background-color: #3790ED;
    border-radius: 10px;
}


.ig-menu-side > .ig-tittle > h1 {
    margin: auto;
    text-align: center;
}

.ig-menu-top {
    display: none;
}

.ig-menu-wraper-container{
    flex-wrap: wrap;
    flex: 1;
    top: 0px;
    left: 20vw;
    width: 80vw;
    margin-left: 1vw;
    margin-right: 1vw;
}

.ig-menu-container{
    padding-top: 2vh;
}


@media (max-width: 480px)  {
    /* =========== btn Close ==================*/

  .ig-menu-btn-close {
    display: block;
    position: absolute;
    top: -1em;
    right: -1em;
    margin: 0;
    border: 0;
    padding: 0;
    background-color: #0066FF;
    border-radius: 100%;
    justify-content: center;
    width: 3em;
    height: 3em;
    padding: auto;
    align-items: center;
    cursor: pointer;
    transition: all 150ms;
    
    .ig-menu-icon-cross {
      color: #ffffff;
      font-size: 2em;
    }
    
    &:hover,
    &:focus {
      transform: rotateZ(180deg);
      background-color: #1e62c7;
    }
  
  }

/* ========== MENU SIDE =======================*/

    .ig-menu-side {
        margin: auto;
        position: absolute;
        top: 50%;
        left: 50%;
        min-width: 90%;
        width: 90% !important;
        height: 90% !important;
        background-color: black;        
        border-radius: 25px;
        padding: 1em;
        z-index: 9999;
        transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        -o-transform: translate(-50%,-50%);
        transition: all 300ms;
    }

    .ig-menu-top {
        display: flex;
        position: sticky;
        border-radius: 25px;
        background-color: #ffffff;
        margin-top: 2vh;
        margin-left: 0px;
        width: 100%;
        min-height: 50px;
        max-height: 100px;
        padding-left: 1em;
        padding-right: 1em;
        vertical-align: middle;
        overflow: visible;
        font-size: 25px;

    }

    .ig-menu-top > span:first-child{
        font-size: 40px;
    }

    .ig-menu-top > div:first-child{
        margin: auto;
        font-size: 40px;
    }




    .ig-menu-top  > div:nth-child(2){
        flex: 1;
        flex-wrap: wrap;
        text-align: end;
        font-size: 25px;
        height: 100%;
        margin: auto;
        overflow-y: auto;
        overflow-wrap: break-word !important;
        padding-left: 1em;
        padding-right: 1em;
    }

    .ig-menu-screen-wrapper{
        display: inline-block;
    }

    .ig-menu-wraper-container{
        display:inline-block;
        width: 100vw;
        margin-left: 1vw;
        margin-right: 1vw;
    }

}