.preloader-hidden {
    visibility: hidden;
    opacity: 0;
    transition: all 250ms linear 500ms;
  }

.preloader {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: #fff;
    z-index: 999999;
  }
  .preloader .inner {
    width: 99px;
    height: 99px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  .preloader .inner > div {
    border-radius: 50%;
    position: absolute;
    -webkit-animation-name: circle_rotate;
    animation-name: circle_rotate;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear cubic-bezier(0.075, 0.82, 0.165, 1);
    animation-timing-function: linear cubic-bezier(0.075, 0.82, 0.165, 1);
    -webkit-animation-duration: 2s;
    animation-duration: 2s;
  }
  .preloader .inner .line1 {
    width: 100%;
    height: 100%;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-top: 6px solid #740eaf;
    border-left: 6px solid rgba(116, 14, 175, 0);
    border-right: 6px solid rgba(116, 14, 175, 0);
    border-bottom: 6px solid rgba(116, 14, 175, 0);
    -webkit-animation-delay: 0s;
    animation-delay: 0s;
  }
  .preloader .inner .line2 {
    width: 70%;
    height: 70%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-top: 6px solid #9726d8;
    border-left: 6px solid rgba(151, 38, 216, 0);
    border-right: 6px solid rgba(151, 38, 216, 0);
    border-bottom: 6px solid rgba(151, 38, 216, 0);
    -webkit-animation-delay: 0.25s;
    animation-delay: 0.25s;
  }
  .preloader .inner .line3 {
    width: 40%;
    height: 40%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    border-top: 6px solid #be70eb;
    border-left: 6px solid rgba(190, 112, 235, 0);
    border-right: 6px solid rgba(190, 112, 235, 0);
    border-bottom: 6px solid rgba(190, 112, 235, 0);
    -webkit-animation-delay: 0.5s;
    animation-delay: 0.5s;
  }

  @-webkit-keyframes circle_rotate {
    50%,
    100% {
      -webkit-transform: rotate(405deg);
      transform: rotate(405deg);
    }
  }
  @keyframes circle_rotate {
    50%,
    100% {
      -webkit-transform: rotate(405deg);
      transform: rotate(405deg);
    }
}