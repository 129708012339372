.ig-prettycontainer-wrapper{
    width: 100%;
    border-radius: 25px;
    border-style: solid;
    border-width: 0px;
    box-shadow: 5px 5px 20px #888888;
    /*box-shadow: #777777 5px;*/
    padding-bottom: 1em;
    padding-left: 1em;
    padding-right: 1em;
    margin-top: 1.5em;
}

.ig-prettycontainer-header {
    position: relative;
    border-radius: 15px;
    margin-left: 1em;
    margin-right: 1em;
    top: -0.5em;
    text-align: start;
    font-weight: bold;
    font-size: 2.5em;
    background-color: #3790ED;
    color: #FFFFFF;
    padding-left: 1em;
}

.ig-prettycontainer-container{
    padding-top: 2em;
    width: 100%;
}